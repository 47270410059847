@font-face {
  font-family: blockchainFont;
  src: url(BLOCKCHAIN.ttf);
}

#name  {
  width: 100%;
  font-family: blockchainFont;
  font-size: 50px;
  position:fixed;
  text-align:left;
  margin-left: 3%;
  margin-top: 0%;
  width: auto;
  top: 0%;
  transform: rotateZ(-5deg);
}

  .animation, .animation_layer {
      height: 100%;
  }

  .night {
    display: flex;
  }
  
  .animation {
    display: block;
      position: absolute;
      z-index: 10;
  }
  
  .animation_layer {
      background-position: top center;
      background-size: cover; /* really important */
      background-repeat:no-repeat;
      max-width: 2000px;
      margin-top: 0%;
      width: 100vw;
      /* height: 100vh; */
      position: relative;
  }
  .animation_layer.parallax {
      position: fixed;
  }
  
  /* .night {
    display:flex;
  } */
  
  #sun {
    /* background:#fffffe;
    border-radius: 50%;
    content: '';
    left: 70%;
    position: absolute;
    top: 20%;
    transform: translate(-50%, -50%);
    max-width: 20%;
    height: 20%; */
    background-image: url(../images/moon.png);
    background-size: 10%;
    left: 20%;
    top: 15%;
  }
  
  #meteor {
    background-image: url(../images/meteor.png);
    top: 0;
    right: 0;
    background-size: 95%;
    background-position: top;
    /* display: inline; */
    /* filter: hue-rotate(180deg) saturate(0.5) brightness(130%) saturate(2);  */
  }
  
  #rect {
    content: "Try Amazing Dark Mode!!";
    background: rgb(0,50,49);
background: linear-gradient(180deg, rgba(0,50,49,1) 0%, rgba(0,50,49,1) 13%, rgba(97,130,121,1) 50%, rgba(182,197,193,1) 60%, rgba(255,255,255,1) 100%);  
  }  
  
  #nftmusic {
    background-image: url(../images/music.webp);
    background-repeat: no-repeat;
    background-size: 9%;
    background-position: right;
    transform: rotate(-30deg);
    right: 10%;
    top: 12%;
    filter: saturate(1) brightness(90%) saturate(2);
  }
  
  #nftexc {
    background-image: url(../images/exchange.webp);
    background-repeat: no-repeat;
    background-size: 6%;
    background-position: left;
    transform: rotate(-30deg);
    left: 5%;
    bottom: 65%;
    filter: saturate(1) brightness(90%) saturate(2);
  }
  
  #nftcoinl {
    background-image: url(../images/coinleft.png);
    background-repeat: no-repeat;
    background-size: 10%;
    background-position: right;
    right: 15%;
    bottom: 20%;
    filter: saturate(0.8) brightness(80%) saturate(2);
  }
  
  #nfteth {
    background-image: url(../images/nfteth1.webp);
    background-repeat: no-repeat;
    background-size: 8%;
    background-position: left;
    left: 15%;
    top: 15%;
    filter: saturate(0.6) brightness(60%) saturate(2);
  }
  
  #nftcoin {
    background-image: url(../images/rocket.webp);
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: top;
    top: 2%;
    filter: saturate(1) brightness(90%) saturate(2); 
  }
  
  #artback {
    background-image: url(../images/background.svg);
    filter: hue-rotate(180deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #mountain {
    background-image: url(../images/mountains.svg);
    filter: hue-rotate(180deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #logoland {
    background-image: url(../images/logo_land.svg);
    filter: hue-rotate(180deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle1 {
    background-image: url(../images/jungle1.svg);
    filter: hue-rotate(180deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle2 {
    background-image: url(../images/jungle2.svg);
    filter: hue-rotate(180deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle3 {
    background-image: url(../images/jungle3.svg);
    filter: hue-rotate(180deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle4 {
    background-image: url(../images/jungle4.svg);
    filter: hue-rotate(180deg) saturate(0.5) brightness(200%) saturate(2); 
  }
  
  #jungle5 {
    background-image: url(../images/jungle5.svg);
    filter: hue-rotate(180deg) saturate(3.5) brightness(200%) saturate(2); 
  }
  
  #manonmountain {
    background-image: url(../images/man_on_mountain.svg);
    filter: hue-rotate(180deg) saturate(3) brightness(180%); 
    /* fill: plum; */
  }
  
  
  
  
  /* dark Mode */
  #rectdconver{
    background-color: #000b18;
  }
  #rectd {
    background-image: url(../images/web3.png);
    /* background-repeat: no-repeat; */
    background-size: 140vmax;
    /* width: 80vw; */
    /* background-position: top; */
    left: 0px;
    top: 0px
  }

  #sun.dark {
    background:#fffffe;
    border-radius: 50%;
    content: '';
    left: 70%;
    position: absolute;
    top: 22%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
  }
  
  #nftcoin.dark {
    background-image: url(../images/game.png);
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: top;
    top: 5%;
    /* filter: hue-rotate(190deg), saturate(0.1) brightness(10%) saturate(2);  */
  }
  
  #cloud.dark {
    background-image: url(../images/cloud.svg);
    background-position: top;
    filter: hue-rotate(-60deg) saturate(0) brightness(250%) saturate(2); 
  }
  
  #artback.dark {
    background: rgb(3, 0, 62);
  background: linear-gradient(180deg, rgb(3, 0, 68) 0%, rgba(6,5,85,1) 10%, rgb(45, 45, 157) 36%, rgb(82, 106, 191) 60%, rgb(0, 0, 0) 100%);
    /* background-color: #2b3003;
    background-image: url(../images/cloud.svg);
    opacity: 50%;
    fill: rgb(43, 52, 96); */
    filter:blur(); 
  }
  
  #mountain.dark {
    background-image: url(../images/mountains.svg);
    filter: hue-rotate(205deg) saturate(0.5) brightness(100%) saturate(2); 
  }
  
  #logoland.dark {
    background-image: url(../images/logo_land.svg);
    filter: hue-rotate(205deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle1.dark {
    background-image: url(../images/jungle1.svg);
    filter: hue-rotate(205deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle2.dark {
    background-image: url(../images/jungle2.svg);
    filter: hue-rotate(205deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle3.dark {
    background-image: url(../images/jungle3.svg);
    filter: hue-rotate(205deg) saturate(0.5) brightness(130%) saturate(2); 
  }
  
  #jungle4.dark {
    background-image: url(../images/jungle4.svg);
    filter: hue-rotate(205deg) saturate(0.5) brightness(200%) saturate(2); 
  }
  
  #jungle5.dark {
    background-image: url(../images/jungle5.svg);
    filter: hue-rotate(205deg) saturate(0.5) brightness(100%) saturate(2); 
  }
  
  #manonmountain.dark {
    background-image: url(../images/man_on_mountain.svg);
    filter: hue-rotate(190deg) saturate(3) brightness(130%); 
    /* fill: plum; */
  }